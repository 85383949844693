<template>
  <div class="alert" :class="{ [variants[variant]]: variant, className }">
    <ion-icon
      v-if="errorIcon"
      class="text-2xl mr-2"
      name="alert-circle-outline"
    ></ion-icon>
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, toRefs, computed } from 'vue';
export default defineComponent({
  props: {
    variant: {
      type: String as PropType<'default' | 'success' | 'warning' | 'error'>,
      default: 'default',
    },
    className: {
      type: String,
      default: '',
    },
  },
  setup: (props) => {
    const { variant } = toRefs(props);

    const variants: any = {
      default: 'bg-gray-500',
      success: 'bg-green-700',
      warning: 'bg-orange-500',
      error: 'bg-red-600',
    };

    const errorIcon = computed(() => {
      return variant.value === 'error';
    });

    return {
      variants,
      errorIcon,
    };
  },
});
</script>
